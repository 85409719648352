<template>
  <form-allocations />
</template>

<script>
  import FormAllocations from '@/components/forms/form-allocations'

  export default {
    name: 'create',
    components: { FormAllocations },
  }
</script>

<style scoped>

</style>
